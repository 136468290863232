<template>
    <div class="chart-widget" style="height:80vh">
        <div class="h-100">
            <div class="row h-100">
                <div class="col h-100">
                    <h5>Historical Prices</h5>
                    <trading-view-widget></trading-view-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { map, clone } from 'lodash';
import { format, parse } from 'date-fns';
import TradingViewWidget from '@/components/TradingViewWidget';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'chartPage',
    components: {
        draggable,
        TradingViewWidget
    },
    methods: {
        getPriceChartInterval() {
            let account = this.$store.state.activeAccount;

            if(this.$store.getters.isHftAccount(account)) {
                return '1';
            }

            return '30';
        }
  
    }
}
</script>