<template>
    <div id="tradingview_trade_detail_widget" style="height: 500px;"></div>
</template>

<script>
export default {
    data() {
        return {
            exchanges: {
                COMEX: [
                    'GC',
                    'HG',
                    'SI'
                ],
                CME_MINI: [
                    'NQ',
                    'EM',
                    'ES'
                ],
                NYMEX: [
                    'CL',
                    'RB',
                    'HO',
                    'NG',
                    'NK',
                    'PL'

                ],
                TASE: [
                    'EU'
                ],
                CME: [
                    'HE',
                    'LE'
                ],
                CBOT: [
                    'ZS',
                    'ZM',
                    'ZL',
                    'ZW',
                    'ZC'
                ]
            }
        };
    },
    props: {
        market: {
            type: String,
            default: 'GC'
        },
        symbol: {
            type: String,
            default: 'GCH21'
        }
    },
    mounted() {
        let symbol = null;
        if(this.symbol !== null) {
            let contractYear = this.symbol.slice(-2);

            if(!isNaN(contractYear)) {
                symbol = `${this.symbol.substr(0, this.symbol.length - 2)}20${contractYear}`;
            }
        }

        if(symbol === null) {
            return;
        }

        let exchange = null;
        for(let key in this.exchanges) {
            if(this.exchanges[key].indexOf(this.market) !== -1) {
                exchange = key;
            }
        }

        if(exchange !== null) {
            symbol = `${exchange}:${symbol}`;
        }

        new TradingView.widget({
            "autosize": true,
            "symbol": symbol,
            "interval": "D",
            "timezone": "America/New_York",
            "theme": "light",
            "style": "1",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "container_id": "tradingview_trade_detail_widget"
        });
    }
}
</script>